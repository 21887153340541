import styled from "@emotion/styled"
import React from "react"
import CookieBanner from "../components/cookieBanner"
import Header from "../components/header"
import Global from "../styles/global"
import Card from "../components/card"
import Typography from "../components/typography"
import { BigPillButton } from "../components/button"
import Link from "../components/link"
import { trackEvent } from "../utils/trackEvent"
import Footer from "../components/footer"
import SEO from "../components/seo"

const ContentWrapper = styled.div`
  margin: 0 auto;
  margin-top: 80px;
`

const ROI = () => {
  return (
    <>
      <Global bg="primary" />
      <Header bg="white" activeTab="learn" />
      <SEO title="ROI Calculator" />
      <ContentWrapper>
        <Card center variant="primary">
          <Typography variant="hero" as="h1">
            ROI with tech support automation
          </Typography>
          <Typography
            variant="h5"
            as="h2"
            style={{
              marginTop: "2rem",
              marginBottom: "5em",
              maxWidth: "600px",
              whiteSpace: "break-spaces",
            }}
          >
            Use automation to solve more requests with less effort and save
            money. This calculator gives you an estimate of how much you can
            save based on actual customer outcomes.
          </Typography>
          <BigPillButton
            to="/roi/inputs"
            as={Link}
            onClick={() =>
              trackEvent({
                category: "view_item",
                label: "start roi calculator",
                siteSection: "roi_start",
              })
            }
            state={{ step: "ROI_INPUTS" }}
            variant="white"
            style={{ height: "90px", outline: "none" }}
          >
            Calculate my roi
          </BigPillButton>
        </Card>
      </ContentWrapper>
      <CookieBanner />
      <Footer />
    </>
  )
}

export default ROI
